import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {Link, useNavigate, useParams} from 'react-router-dom'
import _ from 'lodash'

import useMediaQuery from '@mui/material/useMediaQuery'
import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import {TextField} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import PanelHead from '../../../components/panel-head'
import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import {addEticketPackage, getEticketPackagebyID, editEticketPackage} from '../../../lib/request'

const StyledDiv = styled(Box)``

export default function CreatePackage() {

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const {id} = useParams()
	const eventId = useSelector((state) => state.event.events)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const {me} = useSelector(state => state.profile)

	const [form, setForm] = useState({
		title: '',
		description: '',
		price: '',
		promoPrice: '',
		max: '',
		maxQuantity: 1,
		availableQuantity: '',
		isActive: true,
		eticketEventsId: eventId,
		titleErr: '',
		priceErr: '',
		maxErr: '',
		maxQuantityErr: '',
		entrance: ''
	})

	useEffect(() => {
		if (!isNaN(id)) {
			dispatch(setLoading(true))
			getEticketPackagebyID(id)
				.then((res) => {
					const formClone = _.cloneDeep(res.data)
					setForm(formClone)
				})
				.catch(err => {
					if (err.response) {
						dispatch(setSnackbarMsg(err.response.data.message))
					}
				})
				.finally(() => {
					dispatch(setLoading(false))
				})
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	function changeForm(e) {
		const {name, value} = e.target
		const formClone = _.cloneDeep(form)
		formClone[name] = value

		formClone.titleErr = ''
		formClone.priceErr = ''
		formClone.maxErr = ''
		formClone.maxQuantityErr = ''
		formClone.availableQuantityErr = ''

		if(isNaN(id)){
			formClone.availableQuantity = formClone['max']
		}

		setForm(formClone)
	}

	function changeSwitch(e, v) {
		changeForm({target: {name: e.target.name, value: v}})
	}


	function submit(e) {
		e.preventDefault()
		e.stopPropagation()
		const formClone = _.cloneDeep(form)

		let error = false

		if (_.isEmpty(_.trim(form.title))) {
			formClone.titleErr = 'Title is required'
			error = true
		}

		if (_.isEmpty(_.trim(form.price))) {
			formClone.priceErr = 'Price is required'
			error = true
		}

		if (_.isEmpty(_.trim(form.max))) {
			formClone.maxErr = 'Maximum availability is required'
			error = true
		}

		if (_.isEmpty(_.trim(form.availableQuantity))) {
			formClone.availableQuantityErr = 'Availability quantity is required'
			error = true
		}

		if (_.isEmpty(_.trim(form.maxQuantity))) {
			formClone.maxQuantityErr = 'Maximum buying quantity'
			error = true
		} else if (!isNaN(form.maxQuantity) && !isNaN(form.max) && parseInt(form.maxQuantity) > parseInt(form.max)) {
        formClone.maxQuantityErr = 'Maximum buying quantity cannot be more than maximum availability'
        error = true
    }

		if (error) {
			return setForm(formClone)
		}

		formClone.price = parseFloat(form.price)
		formClone.promoPrice = parseFloat(form.promoPrice)

		setForm(formClone)
		dispatch(setLoading(true))

		addEticketPackage(formClone)
			.then((res) => {
				dispatch(setSnackbarMsg('Package created'))
				navigate(`/eticket/event/${eventId}`)
			})
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	function updateSubmit(e) {
		e.preventDefault()
		e.stopPropagation()
		const formClone = _.cloneDeep(form)

		let error = false

		if (_.isEmpty(_.trim(form.title))) {
			formClone.titleErr = 'Title is required'
			error = true
		}

		if (_.isEmpty(_.trim(form.price))) {
			formClone.priceErr = 'Price is required'
			error = true
		}

		if (_.isEmpty(_.trim(form.max))) {
			formClone.maxErr = 'Max availability is required'
			error = true
		}


		if (error) {
			return setForm(formClone)
		}

		setForm(formClone)
		dispatch(setLoading(true))
		editEticketPackage(id, formClone)
			.then((res) => {
				dispatch(setSnackbarMsg('Package updated'))
				navigate(`/eticket/event/${eventId}`)
			})
			.catch(err => {
				if (err.response) {
					dispatch(setSnackbarMsg(err.response.data.message))
				}
			})
			.finally(() => dispatch(setLoading(false)))
	}

	return (
		<StyledDiv>
			<PanelHead title={!isNaN(id) ? `Update Package Details` : `Create New Package`}>
				<Link to={`/eticket/event/${eventId}`}>
					<Button
						fullWidth={isMobile}
						variant={isMobile ? 'outlined' : 'text'}
						color={'inherit'} sx={{mr: isMobile ? 0 : 2}}>
						Back
					</Button>
				</Link>
				{
					!isNaN(id) ? (
						<>
							{
								!_.isEmpty(me) && (me.permission.includes('U')) && (
									<Button fullWidth={isMobile} variant="outlined" onClick={updateSubmit} sx={{mt: isMobile ? 1 : 0}}>
										Update
									</Button>
								)
							}
						</>
					) : (
						<>
							<Button fullWidth={isMobile} variant="outlined" onClick={submit} sx={{mt: isMobile ? 1 : 0}}>
								Save
							</Button>
						</>
					)
				}
			</PanelHead>

			<Box sx={{p: 2}}>
				<Box component="form" noValidate onSubmit={submit}>
					<Grid container spacing={2}>
						<Grid xs={12} md={6}>
							<TextField
								required
								fullWidth
								type="text"
								margin="normal"
								label="Title"
								name="title"
								InputLabelProps={{shrink: true}}
								error={!_.isEmpty(form.titleErr)}
								helperText={form.titleErr}
								value={form.title}
								onChange={changeForm}/>
							<TextField
								required
								fullWidth
								type="number"
								margin="normal"
								label="Price"
								name="price"
								InputLabelProps={{shrink: true}}
								error={!_.isEmpty(form.priceErr)}
								helperText={form.priceErr}
								value={form.price}
								onChange={changeForm}/>
							<TextField
								fullWidth
								type="text"
								margin="normal"
								label="Maximum availability"
								name="max"
								InputLabelProps={{shrink: true}}
								error={!_.isEmpty(form.maxErr)}
								helperText={form.maxErr}
								value={form.max}
								onChange={changeForm}/>
							<TextField
								fullWidth
								type="text"
								margin="normal"
								label="Available quantity"
								name="availableQuantity"
								InputLabelProps={{shrink: true}}
								error={!_.isEmpty(form.availableQuantityErr)}
								helperText={form.availableQuantityErr}
								value={form.availableQuantity}
								onChange={changeForm}/>
							<TextField
								fullWidth
								type="text"
								margin="normal"
								label="Max buying quantity"
								name="maxQuantity"
								InputLabelProps={{shrink: true}}
								value={form.maxQuantity}
								error={!_.isEmpty(form.maxQuantityErr)}
								helperText={form.maxQuantityErr}
								onChange={changeForm}/>
						</Grid>
						<Grid xs={12} md={6}>
							<TextField
								multiline
								rows={3}
								label="Description"
								name="description"
								fullWidth
								margin="normal"
								value={form.description}
								InputLabelProps={{shrink: true}}
								onChange={changeForm}/>
							<FormGroup component="fieldset">
								<FormControlLabel
									control={<Switch checked={form.isActive} name={'isActive'} onChange={changeSwitch}/>}
									label="is available right now"/>
							</FormGroup>
							<TextField
								fullWidth
								type="text"
								margin="normal"
								label="Entrance"
								name="entrance"
								InputLabelProps={{shrink: true}}
								value={form.entrance}
								onChange={changeForm}/>
							<TextField
								fullWidth
								type="number"
								margin="normal"
								label="Promo Price (Eg. early bird)"
								name="promoPrice"
								InputLabelProps={{shrink: true}}
								value={form.promoPrice}
								onChange={changeForm}/>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</StyledDiv>
	)
}
